import { Link } from '@/components/Link'
import { Loader } from '@/components/Loader'
import { activateAccount } from '@/features/auth/service'
import { CookieBanner } from '@/features/cookies/CookieBanner'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { useSearchParams } from '@/hooks/use-search-params'
import { PAGES } from '@/routes'
import { getButtonClassName } from '@blockchain-traceability-sl/tailwind-components'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { PageProps } from 'gatsby'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'

const VerifyAccountPage = ({
  pageContext,
}: PageProps<never, { language: string }>): JSX.Element => {
  const { t, i18n } = useTranslation('nsVerifyAccountPage')
  const cookiesConfig = useCookiesConfig()

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  const params = useSearchParams<{ token: string }>()
  const [status, setStatus] = useState<'pending' | 'error' | 'success'>('pending')

  useEffect(() => {
    if (!params.token) {
      setStatus('error')
    } else {
      activateAccount(params.token)
        .then(() => {
          setStatus('success')
        })
        .catch(() => {
          setStatus('error')
        })
    }
  }, [i18n, params.token])

  // Analytics
  useEffect(() => {
    if (status === 'success') {
      window.analytics?.track('CUSTOMER_ACTIVATE_ACCOUNT')
    }
  }, [status])

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Segment analytics */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=window.GATSBY_APP_SEGMENT_WRITEKEY;;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load(window.GATSBY_APP_SEGMENT_WRITEKEY);
          analytics.page();
          }}();
        `}</script>
        )}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>

      <div className='w-screen h-screen pt-16 pb-12 flex flex-col bg-white'>
        {status === 'pending' ? (
          <Loader center='full' />
        ) : (
          <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex-shrink-0 flex justify-center'>
              {status === 'success' && <CheckCircleIcon className='h-24 w-auto text-green-500' />}
              {status === 'error' && <XCircleIcon className='h-24 w-auto text-red-500' />}
            </div>
            <div className='py-16'>
              <div className='text-center'>
                <h1 className='mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
                  {t(`${status}.title`)}
                </h1>
                <p className='mt-2 text-base text-gray-500'>
                  <Trans
                    t={t}
                    i18nKey={`${status}.description`}
                    components={{
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      a: <a href='mailto:info@trazable.io' className='underline' />,
                    }}
                  />
                </p>
                <div className='mt-6'>
                  <Link
                    to={PAGES.HOME}
                    query='login'
                    className={getButtonClassName({})}
                    onClick={() => {
                      window.analytics?.track('ACTION_CLICK_LOGIN', { Section: 'Activate account' })
                    }}
                  >
                    {t(`${status}.action`)}
                  </Link>
                </div>
              </div>
            </div>
          </main>
        )}
      </div>

      <CookieBanner />
    </>
  )
}

export default VerifyAccountPage
