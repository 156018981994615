import { ImgHTMLAttributes } from 'react'
import classNames from 'classnames'
import LoaderSVG from './loader.svg'
import { SIZE } from '@blockchain-traceability-sl/tailwind-components'

export interface LoaderProps extends ImgHTMLAttributes<HTMLImageElement> {
  /**
   * How large should be
   */
  size?: SIZE
  center?: 'none' | 'horizontal' | 'vertical' | 'full'
}

/**
 * Loader UI component for user interaction
 */
export const Loader = ({
  size = SIZE.MEDIUM,
  className,
  center = 'none',
}: LoaderProps): JSX.Element => {
  return (
    <div
      data-testid='loader-wrapper'
      className={classNames(
        'w-full h-full flex',
        {
          'justify-center': center === 'horizontal',
          'items-center': center === 'vertical',
          'justify-center items-center': center === 'full',
        },
        className
      )}
    >
      <object
        data={LoaderSVG}
        type='image/svg+xml'
        className={classNames(
          // 'inline-block',
          {
            'h-8 w-8': size === SIZE.EXTRA_SMALL,
            'h-16 w-16': size === SIZE.SMALL,
            'h-32 w-32': size === SIZE.MEDIUM,
            'h-48 w-48': size === SIZE.LARGE,
            'h-60 w-60': size === SIZE.EXTRA_LARGE,
          },
          className
        )}
        aria-label='loading'
      />
    </div>
  )
}
